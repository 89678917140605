import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Select, MenuItem, CircularProgress } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

import Content from "./Content/Content";
import { request } from "../api";
import { CONTENT_URL, MEMBERS_URL } from "../api/urls";

export const ShotgunLeaderboard = () => {
  const theme = useTheme();
  const [url, setUrl] = useState(null);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedBeeber, setSelectedBeeber] = useState('');

  // Define the background style in one place
  const backgroundStyle = { background: blueGrey[100] };

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryParams = new URLSearchParams();

    queryParams.set("kind", 4)

    if (selectedMonth) {
      queryParams.set("month", selectedMonth);
    }
    if (selectedBeeber) {
      queryParams.set("creator", selectedBeeber);
    }
    const newUrl = `${CONTENT_URL}?${queryParams.toString()}`;
    setUrl(newUrl);
  }, [selectedMonth, selectedBeeber]);

  async function fetchMembers() {
    const response = await request(MEMBERS_URL);
    const { results } = response;
    const activeMembers = results.filter(member => member.is_active).filter(member => member.name !== 'bbot');
    setMembers(activeMembers);
  }

  async function fetchContent(url, append = true) {
    setLoading(true)
    const response = await request(url);
    setContent(response);
    setLoading(false);
  }

  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    fetchContent(url, false);
  }, [url]);

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
  };

  const handleBeeberChange = (event) => {
    const beeber = event.target.value;
    setSelectedBeeber(beeber);
  };

  if (loading) {
    return <CircularProgress variant="indeterminate" />
  }

  return (
    <Grid container spacing={2} style={backgroundStyle}> {/* Apply background style here */}
      <Grid item md={4} xs={5}>
        <Select
          value={selectedBeeber}
          onChange={handleBeeberChange}
          displayEmpty
          fullWidth
          sx={{ background: '#FFF', marginLeft: '1rem' }}
        >
          <MenuItem value="">
            All members
          </MenuItem>
          {members.map((member) => (
            <MenuItem key={member.id} value={member.id}>
              {member.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item md={3} xs={1} />

      <Grid item md={4} xs={5}>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          displayEmpty
          fullWidth
          sx={{ background: '#FFF' }}
        >
          <MenuItem value="">
            All months
          </MenuItem>
          {Array.from({ length: 4 }, (_, i) => i + 9).map((month) => (
            <MenuItem key={month} value={month}>
              {new Date(0, month - 1).toLocaleString('default', { month: 'long' })}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid container spacing={1} style={{ padding: "32px" }}> {/* Removed background here */}

      <Grid item xs={12} style={backgroundStyle}> {/* Apply background style here */}
        <Typography variant="h6" gutterBottom>Total: {content.length}</Typography>

        {content.length ? content.map((c) => (
          <Content key={c.id} content={c} />
        )) : 
          <Typography variant="h6" color={theme.palette.primary.main}>
            No content found
          </Typography>
        }
        </Grid>
      </Grid>
    </Grid>
  );
};