import { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { FormattedTime } from '../Common';

import { request } from "../../api";
import { CONTENT_URL } from '../../api/urls';

export default function Content(props) {
  const { content } = props;
  const [contentDetails, setContentDetails] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const contentUrl = `${CONTENT_URL}${content.id}/`;

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (isExpanded && !contentDetails) {
      setLoading(true)
      // Fetch content details only if the accordion is being opened
      request(contentUrl)
        .then(response => {
          setContentDetails(response);
        })
        .catch(error => {
          console.error("Error fetching content details:", error);
        });
      setLoading(false)
    }
  };

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography color="textSecondary">
          #{content.index}: {FormattedTime(content.create_date)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12}>

            {contentDetails ? (
              <div style={{ padding: '8px'}}>
                {contentDetails.kind === 2 && (
                  <img
                    style={{ width: 300 }}
                    src={contentDetails.upload}
                    alt={contentDetails.text}
                  />
                )}
                {contentDetails.kind === 3 || contentDetails.kind === 4 && (
                  <video
                    style={{ width: 300, marginBottom: '1rem' }}
                    src={contentDetails.upload}
                    controls
                  />
                )}

                {content.text ? (
                  <Typography gutterBottom component="div">
                    "{contentDetails.text}" - <i>{content.creator_nickname}</i>
                  </Typography>
                ) : (
                  <Typography gutterBottom component="div">
                    <i>{content.creator_nickname}</i>
                  </Typography>
                )}
                <Typography variant="caption">Uploaded by {content.creator_name}</Typography>
                {contentDetails.description && (
                  <Typography color="textSecondary">
                    {contentDetails.description}
                  </Typography>
                )}
              </div>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
