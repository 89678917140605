import React, { useEffect, useState } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import { blueGrey } from '@mui/material/colors';

import ResponsiveAppBar from './components/AppBar';
import { Feed } from './components/Feed';
import { request } from './api';
import { CURRENT_USER_URL } from './api/urls';
import { ShotgunLeaderboard } from './components/ShotgunLeaderboard';

const outerTheme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    background: {
      default: blueGrey[100],
    }
  },
});

export default function App() {
  const [processing, setProcessing] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    async function fetchUser() {
      setProcessing(true);
      try {
        const userData = await request(CURRENT_USER_URL);
        if (userData.messages?.length) {
          console.log({userData})
        }
        else {
          setLoggedIn(true);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
      setProcessing(false);
    }
    fetchUser();
  }, []);

  const handleLogin = e => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_DJANGO_URL}api/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    })
      .then(res => res.json())
      .then(json => {
        if (json.access) {
          setLoggedIn(true);
          localStorage.setItem("access", json.access);
          localStorage.setItem("refresh", json.refresh)
        }
      })
      .catch(error => {
        setLoginError(true);
        setTimeout(() => setLoginError(false), 2000);
        setLoggedIn(false);
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
      });
  };

  return (
    <ThemeProvider theme={outerTheme}>
      <ResponsiveAppBar />
      <Container sx={{ width: "100vw", minHeight: "calc(100vh - 64px)", py: 4, background: blueGrey[100] }}>
          {processing ? <CircularProgress /> :
          loggedIn ? (
            <Grid container spacing={2}>
              <Grid xs={12}>
                <ShotgunLeaderboard />
              </Grid>
            </Grid>
          ) : (
            <form onSubmit={handleLogin}>
              <TextField
                id="username"
                helperText="username"
                autoFocus={true}
                onChange={e => setUsername(e.target.value.trim())}
                value={username}
                fullWidth
              />
              <TextField
                id="password"
                onChange={e => setPassword(e.target.value.trim())}
                helperText="password"
                type="password"
                value={password}
                fullWidth
              />
              <Fab
                variant="extended"
                style={{ padding: "20px", margin: "20px" }}
                color="primary"
                type="submit"
              >
                Login
              </Fab>
              {loginError && (
                <img width="150px" src="/dikembe.gif" alt="dikembe no no" />
              )}
            </form>
            
          )}
      </Container>
    </ThemeProvider>
  );
}